import React from "react"
import {Helmet} from "react-helmet"
import borderCircle from "../../images/community/border-circle.svg"
import checkCircle from "../../images/community/check-circle.svg"
import Ejaz from "../../images/community/Ejaz.jpg"
import Steven from "../../images/community/Steven.jpg"
import Hunter from "../../images/community/Hunter.jpg"
import {FaFacebook, FaInstagram, FaLinkedin, FaTwitter,FaTelegram} from "react-icons/fa";

const Community = () => {
    return (
        <div className="trondao-community">
            <Helmet>
                <title>TRON Community | TRONDAO</title>
                <meta name="description"
                      content="Not Yet"/>
                <meta property="og:description" content="Not Yet"/>
                <meta property="og:title" content="TRON Community | TRONDAO"/>
            </Helmet>

            <div className="community gaming-dark-color">
                <div className="community-main">
                    <div
                        className="community-title flex-column d-flex flex-md-row align-items-center justify-content-center col-md-12">
                        <div className="d-flex flex-column align-items-center">
                            <h1 className="text-uppercase text-center">Become a <span>#Tronic</span></h1>
                            <p className="col-12 col-md-8 mx-auto text-center mt-md-4">Looking to help build the future
                                of the internet? Well... what are you waiting for? Become a TRONIC now!</p>
                            <a
                                href="/#"
                                target="_blank"
                                rel="noopener noreferrer"
                                id="community-cta-button"
                            >
                                <button className="gaming-title-start-button">
                                    Learn More
                                </button>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="community-trait d-flex flex-column align-items-center justify-content-center">
                    <div>
                        <h1 className="community-headline">Reliable Community</h1>
                    </div>

                    <div className="community-row d-flex flex-column flex-md-row justify-content-center">
                        <div className="col-12 col-md-5 col-lg-6">
                            <div className="community-features d-flex flex-column align-items-center col-12">
                                <img src={checkCircle} alt="check circle with rotating arrow icon"
                                     className="mb-2 mb-lg-0"></img>
                                <h1 className="text-uppercase my-4">Adaptive</h1>
                                <p className="col-12 mx-auto text-center">
                                    If you're passionate about TRON, blockchain technology, or more, TRONICS have likely
                                    built a community just for you.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-6">
                            <div className="community-features d-flex flex-column align-items-center col-12">
                                <img src={borderCircle} alt="circle with outside border icon"
                                     className="mb-2 mb-lg-0"></img>
                                <h1 className="text-uppercase my-4">Strong</h1>
                                <p className="col-12 mx-auto text-center">
                                    TRONICS act as the backbone of the network, providing computational power and
                                    support for the various dApps and features that make up the TRON ecosystem.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="community-grants d-flex flex-column mx-auto">
                    <div
                        className="community-grants-header d-flex flex-column align-items-center justify-content-center col-12">
                        <h1 className="text-uppercase text-center">Grants</h1>
                        <p className="col-12 col-md-7 mx-md-auto text-md-center text-justify">
                            We recognize that TRONICS are the heart of TRON and represent its future. Understanding
                            that, we find it imperative to provide the tools to turn your brilliant ideas into
                            realities.
                        </p>
                    </div>

                    <div className="community-grants-subheader d-flex flex-column flex-md-row col-12">
                        <div
                            className="col-12 col-md-4 d-flex flex-column justify-content-between text-center mb-5 mb-md-0">
                            <h1 className="text-uppercase">Community</h1>
                            <p className="text-justify text-md-center">
                                TRON provides valuable funding and resources to creators who are building projects
                                within its ecosystem.
                            </p>
                            <a
                                href="/ambassador-grants/#grants-community/"
                                target="_blank"
                                rel="noopener noreferrer"
                                id="community-grants-button"
                            >
                                <button className="community-grants-subheader-button">
                                    Call To Action
                                </button>
                            </a>
                        </div>
                        <div
                            className="col-12 col-md-4 d-flex flex-column justify-content-between text-center mb-5 mb-md-0">
                            <h1 className="text-uppercase">Influencer</h1>
                            <p className="text-justify text-md-center">
                                TRON is looking to support TRON eco-educators and influencers who are working on
                                DAO-related projects and outreach.
                            </p>
                            <a
                                href="/ambassador-grants/#grants-influencers/"
                                target="_blank"
                                rel="noopener noreferrer"
                                id="influencer-grants-button"
                            >
                                <button className="community-grants-button">
                                    Call To Action
                                </button>
                            </a>
                        </div>
                        <div className="col-12 col-md-4 d-flex flex-column justify-content-between text-center">
                            <h1 className="text-uppercase">Developer</h1>
                            <p className="text-justify text-md-center">
                                TRON is looking to help developers that are making this network more secure through
                                discovery of bugs and vulnerabilties
                            </p>
                            <a
                                href="/ambassador-grants/#grants-developers/"
                                target="_blank"
                                rel="noopener noreferrer"
                                id="community-grants-button"
                            >
                                <button className="community-grants-button">
                                    Call To Action
                                </button>
                            </a>
                        </div>
                    </div>
                </div> */}

                <div className="community-team d-flex flex-column justify-content-center">
                    <div>
                        <h1 className="community-headline text-center">Responsive Team</h1>
                    </div>

                    <div
                        className="community-team-members community-trait d-flex flex-column flex-md-row justify-content-center align-items-center mx-auto mx-md-0 mx-xl-auto">
                        {/* {
                            [...Array(3).keys()].map(i => {
                                return ( */}
                                    
                                    <div  className="col-12 col-md-3 col-xl-4 mb-4 mb-lg-0">
                                        <div
                                            className="community-team-member d-flex flex-column text-center justify-content-between col-12">
                                            <img src={Steven} alt="avatar" className="img-fluid d-block mx-auto"></img>
                                            <h1>Steven George Bischoff</h1>
                                            <p>
                                            Community Manager at TRON DAO,With a background supporting SAAS technology, Community relations, and Ecosystem Development. Steven’s goal is to help educate and bring more users to the world of Web3!.
                                            </p>
                                            <div
                                                className="community-team-member-socials d-flex flex-row align-items-center justify-content-between col-8 mx-auto">

                                                <a id="email-capture-social-twitter"
                                                   aria-label="social-icon-twitter"
                                                   href="https://twitter.com/St3venTRON"
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                    <FaTwitter size={20}/>
                                                </a>
    
                                                <a id="email-capture-social-telegram"
                                                   aria-label="social-icon-telegram"
                                                   href="https://t.me/St3venTRON"
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                    <FaTelegram size={20}/>
                                                </a>                                                
                                                <a id="email-capture-social-linkedin"
                                                   aria-label="social-icon-linkedin"
                                                   href="https://www.linkedin.com/in/steven-bischoff"
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                    <FaLinkedin size={20}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div  className="col-12 col-md-3 col-xl-4 mb-4 mb-lg-0">
                                        <div
                                            className="community-team-member d-flex flex-column text-center justify-content-between col-12">
                                            <img src={Hunter} alt="avatar" className="img-fluid d-block mx-auto"></img>
                                            <h1>Hunter</h1>
                                            <p>
                                            For almost a decade, Hunter has been actively involved in contributing to open-source blockchain projects, collaborating with other experts in the industry, and working alongside developers to decentralize the web. 
                                            </p>
                                            <div
                                                className="community-team-member-socials d-flex flex-row align-items-center justify-content-between col-8 mx-auto">
                                                <a id="email-capture-social-twitter"
                                                   aria-label="social-icon-twitter"
                                                   href="https://twitter.com/ibeatwallstreet"
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                    <FaTwitter size={20}/>
                                                </a>
                                              
                                                <a id="email-capture-social-linkedin"
                                                   aria-label="social-icon-linkedin"
                                                   href="https://www.linkedin.com/in/ibeatwallstreet"
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                    <FaLinkedin size={20}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div  className="col-12 col-md-3 col-xl-4 mb-4 mb-lg-0">
                                        <div
                                            className="community-team-member d-flex flex-column text-center justify-content-between col-12">
                                            <img src={Ejaz} alt="avatar" className="img-fluid d-block mx-auto"></img>
                                            <h1>Ejaz</h1>
                                            <p>
                                            Ejaz is a hospitality graduate who changed careers to become a developer and product manager because he loves to design and produce software. He enjoys traveling and learning about various cultures, sites, and foods. 
                                            </p>
                                            <div
                                                className="community-team-member-socials d-flex flex-row align-items-center justify-content-between col-8 mx-auto">
                                                <a id="email-capture-social-facebook"
                                                   aria-label="social-icon-facebook"
                                                   href="https://www.facebook.com/EMerchant90/"
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                    <FaFacebook size={20}/>
                                                </a>
                                                <a id="email-capture-social-twitter"
                                                   aria-label="social-icon-twitter"
                                                   href="https://twitter.com/EjazMerchant"
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                    <FaTwitter size={20}/>
                                                </a>
                                                <a id="email-capture-social-instagram"
                                                   aria-label="social-icon-instagram"
                                                   href="https://www.instagram.com/emerchant9/"
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                    <FaInstagram size={20}/>
                                                </a>                                            
                                                <a id="email-capture-social-linkedin"
                                                   aria-label="social-icon-linkedin"
                                                   href="https://www.linkedin.com/in/emerchant90/"
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                    <FaLinkedin size={20}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                 {/* )
                             })
                         } */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Community